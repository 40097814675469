import React, { useRef } from "react";
import Icon from "Components/Shared/Icon";
import Popup from "Components/Shared/Popup";

interface IProps {
	labelText: string;
	name: string;
	type?: string;
	autoFocus?: boolean;
	invalidMessage?: string;
	disabled?: boolean;
	testId?: string;

	setFocusOnSearchList?: (focusOnSearchList: boolean) => void;
	onChangeInputText?: (arg?: any) => void;
	onInputBlur?: (item: any) => void;

	incrementalSearchHookProps: any;
}

const IncrementalSearchInput: React.FunctionComponent<IProps> = ({
	labelText,
	name,
	type = "text",
	autoFocus = true,
	invalidMessage = "",
	disabled = false,
	testId = name,

	setFocusOnSearchList = () => {},
	onChangeInputText = () => {},
	onInputBlur = () => {},

	incrementalSearchHookProps,
}) => {
	const { inputText, setInputText, setShowList, filteredList } =
		incrementalSearchHookProps;

	const inputRef = useRef(null) as any;

	const onChangeText = (value: string) => {
		setInputText(value);
		onChangeInputText(value);
	};

	const onBlur = () => {
		let item;

		if (filteredList?.length === 1) {
			item = filteredList[0];
		}

		onInputBlur(item);
	};

	const onInputFocus = () => {
		setShowList(true);
		setFocusOnSearchList(false);
		if (inputRef.current && document.activeElement !== inputRef.current) {
			inputRef.current.focus();
		}
	};

	const onKeyDown = (event: any) => {
		if (event.key === "ArrowDown") {
			setFocusOnSearchList(true);
		}
		if (event.key === "Tab") {
			setFocusOnSearchList(false);
			setShowList(false);
		}
		if (event.key === "Backspace") {
			setShowList(true);
		}
		if (event.key === "Enter") {
			event.preventDefault();
		}
	};

	const onClearInputValue = () => {
		onChangeText("");
		onInputFocus();
	};

	return (
		<>
			<Popup
				show={invalidMessage.length > 0}
				text={invalidMessage}
				testId={testId}
			/>
			<figure
				id="incrementalSearchInput"
				className={inputText ? "uploaded-images__figure" : ""}
			>
				{type === "search" && <Icon name="loupe" />}
				<input
					autoComplete="off"
					ref={inputRef}
					autoFocus={autoFocus}
					id={name}
					className="form-control textInput"
					value={!inputText ? "" : inputText}
					type={type}
					name={name}
					placeholder={type === "search" ? labelText : ""}
					data-empty={!inputText}
					data-testid={`${testId}-input`}
					onChange={(e) => onChangeText(e.target.value)}
					onKeyDown={(e) => onKeyDown(e)}
					onFocus={onInputFocus}
					onClick={onInputFocus}
					disabled={disabled}
					onBlur={onBlur}
				/>
				{type !== "search" && (
					<label className="form-control-label" htmlFor={name}>
						{labelText}
					</label>
				)}
				<button
					className="uploaded-images__remove btn-no-style"
					style={
						name !== "fabricContent"
							? { top: "auto", right: "10px" }
							: { top: "auto" }
					}
					id={`${name}Close`}
					onClick={onClearInputValue}
					data-testid={`clear-${testId}`}
				>
					<Icon name="cross-rounded-filled" className=" searchClearIcon" />
				</button>
			</figure>
		</>
	);
};

export default IncrementalSearchInput;
