import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import Icon from "Components/Shared/Icon";
import { marked } from "marked";

import "./styles/ChatBot.css";
import { toast } from "react-toastify";
import { getKey, hashString } from "Utils/crypto";

interface LocalMessage {
	id: number;
	content: string;
	sender: "user" | "bot";
	timestamp: number;
	messageType?: "note" | "regular";
}

interface ServerMessage {
	type: "message" | "action";
	content: string;
}

const WS_URL_BASE = import.meta.env.VITE_APP_BACKEND_WS_URL;

const getStorageKey = async (userId: number): Promise<string> => {
	const hashedId = await hashString(userId.toString());
	return `c${hashedId}`;
};

export async function saveMessages(
	messages: any[],
	userId: number,
	userName: string
) {
	const storageKey = await getStorageKey(userId);
	const salt = `${userId}-${userName}`;
	const key = await getKey(userId.toString(), salt);
	const iv = crypto.getRandomValues(new Uint8Array(12));
	const encoder = new TextEncoder();

	const encrypted = await crypto.subtle.encrypt(
		{ name: "AES-GCM", iv },
		key,
		encoder.encode(JSON.stringify(messages))
	);

	localStorage.setItem(
		storageKey,
		JSON.stringify({
			iv: Array.from(iv),
			data: Array.from(new Uint8Array(encrypted)),
		})
	);
}

export async function loadMessages(userId: number, userName: string) {
	const storageKey = await getStorageKey(userId);
	const stored = localStorage.getItem(storageKey);
	if (!stored) return [];

	const { iv, data } = JSON.parse(stored);
	const key = await getKey(userId.toString(), `${userId}-${userName}`);
	const decoder = new TextDecoder();

	try {
		const decrypted = await crypto.subtle.decrypt(
			{ name: "AES-GCM", iv: new Uint8Array(iv) },
			key,
			new Uint8Array(data)
		);
		return JSON.parse(decoder.decode(decrypted));
	} catch {
		return [];
	}
}

const generateMessageId = (): number => {
	const id = Date.now() + Math.floor(Math.random() * 1000);
	return id;
};

const ChatBot: React.FC<{
	userId: number;
	userName: string;
	isOnline: boolean;
}> = ({ userId, userName, isOnline }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [messages, setMessages] = useState<LocalMessage[]>([]);
	const [inputMessage, setInputMessage] = useState("");
	const [chatSize, setChatSize] = useState({ width: 530, height: 650 });
	const [loadingPhase, setLoadingPhase] = useState<
		"thinking" | "typing" | "still-typing" | "more-typing" | null
	>(null);
	const ws = useRef<WebSocket | null>(null);
	const messagesEndRef = useRef<HTMLDivElement>(null);
	const textareaRef = useRef<HTMLTextAreaElement>(null);
	const streamingMessageRef = useRef<LocalMessage | null>(null);
	const isReconnectingRef = useRef(false);
	const reconnectToastIdRef = useRef<React.ReactText | null>(null);
	const [isClearing, setIsClearing] = useState(false);
	const reconnectAttemptsRef = useRef(0);
	const [reconnectAttempts, setReconnectAttempts] = useState(0);
	const [isConnecting, setIsConnecting] = useState(false);
	const maxReconnectAttempts = 3;

	useEffect(() => {
		const loadStoredMessages = async () => {
			try {
				const messages = await loadMessages(userId, userName);
				if (messages && messages.length > 0) {
					const filteredMessages = messages.filter(
						(msg: LocalMessage) => !isNaN(msg.timestamp)
					);
					setMessages(filteredMessages);
					if (filteredMessages.length === 0) {
						addWelcomeMessage();
					}
				} else {
					addWelcomeMessage();
				}
			} catch (err) {
				console.error("Error loading messages:", err);
				addWelcomeMessage();
			}
		};

		const addWelcomeMessage = () => {
			const greetingMessage: LocalMessage = {
				id: generateMessageId(),
				content:
					"Hello! I'm your Cacotec assistant. Feel free to ask in any language.",
				sender: "bot",
				timestamp: new Date().getTime(),
			};

			const noteMessage: LocalMessage = {
				id: generateMessageId(),
				content:
					"<small>_While I aim to provide accurate information, please verify critical details with official sources._</small>",
				sender: "bot",
				timestamp: new Date().getTime(),
				messageType: "note",
			};

			setMessages([greetingMessage, noteMessage]);
		};

		if (isOpen) {
			loadStoredMessages();
		}
	}, [userId, userName, isOpen]);

	useEffect(() => {
		if (messages.length > 0) {
			saveMessages(messages, userId, userName);
		}
	}, [messages, userId, userName]);

	useEffect(() => {
		return () => {
			if (ws.current) {
				ws.current.close();
			}
		};
	}, []);

	// If we're opening the chat
	useEffect(() => {
		if (isOpen && !ws.current) {
			handleOpenChat();
		}
	}, [isOpen]);

	// If we're coming back online and the chat is open
	useEffect(() => {
		if (isOnline && isOpen && !ws.current) {
			// Try to reconnect
			handleOpenChat();
		}
	}, [isOnline, isOpen]);

	const closeWebSocket = () => {
		if (ws.current && ws.current.readyState === WebSocket.OPEN) {
			// Send a close action to the server
			const serverMessage: ServerMessage = {
				type: "action",
				content: "close",
			};
			ws.current.send(JSON.stringify(serverMessage));

			// Explicitly close the WebSocket connection
			ws.current.close(1000, "User closed chat");

			// Clear the WebSocket reference
			ws.current = null;

			setLoadingPhase(null);
		}
	};

	const handleOpenChat = () => {
		setIsOpen(true);
		setIsConnecting(true);
		// Initialize WebSocket connection when chat is opened
		// Close any existing WebSocket before creating a new one
		if (ws.current) {
			console.log("Closing existing WebSocket before creating new one");
			ws.current.close();
			ws.current = null;
		}

		ws.current = new WebSocket(WS_URL_BASE);

		ws.current.onmessage = async (event) => {
			const messageData = JSON.parse(event.data);

			const { type, content } = messageData;

			if (type === "action" && content === "cleared") {
				setMessages([]);
				setIsClearing(false);
				const storageKey = await getStorageKey(userId);
				localStorage.removeItem(storageKey);
			} else if (type === "stream") {
				if (!streamingMessageRef.current && !messageData.done) {
					const newMessage: LocalMessage = {
						id: generateMessageId(),
						content: messageData.content,
						sender: "bot",
						timestamp: new Date().getTime(),
					};
					streamingMessageRef.current = newMessage;
					addMessage(newMessage);
				} else if (streamingMessageRef.current && !messageData.done) {
					// Append to existing message
					const updatedMessage = {
						...streamingMessageRef.current,
						content: streamingMessageRef.current.content + messageData.content,
					};
					streamingMessageRef.current = updatedMessage;
					setMessages((prev) =>
						prev.map((msg) =>
							msg.id === streamingMessageRef.current!.id ? updatedMessage : msg
						)
					);
				} else if (messageData.done) {
					// Reset streaming state
					streamingMessageRef.current = null;
					setLoadingPhase(null);
				}
			}
		};

		ws.current.onclose = (event) => {
			// Don't attempt to reconnect if this was a normal closure
			if (event.code === 1000) {
				isReconnectingRef.current = false;
				return;
			}

			if (reconnectAttemptsRef.current < maxReconnectAttempts) {
				isReconnectingRef.current = true;
				const delay = Math.min(
					2000 * Math.pow(2, reconnectAttemptsRef.current),
					10000
				);

				reconnectAttemptsRef.current += 1;

				setReconnectAttempts(reconnectAttemptsRef.current);

				// Create or update toast
				if (reconnectToastIdRef.current === null) {
					// First attempt - create new toast
					reconnectToastIdRef.current = toast.warn(
						"Connection lost. Reconnecting...",
						{
							autoClose: false, // Disable auto-close
							closeOnClick: false, // Prevent closing when clicked
							draggable: false, // Prevent closing by dragging
							closeButton: false, // Show close button for accessibility
							pauseOnHover: false, // Don't pause timer on hover (not relevant since autoClose is false)
						}
					);
				} else {
					// Subsequent attempts - update existing toast
					toast.update(reconnectToastIdRef.current, {
						render: "Connection lost. Reconnecting...",
					});
				}

				setTimeout(() => {
					if (isOpen) {
						if (ws.current) {
							ws.current.close();
							ws.current = null;
						}
						handleOpenChat();
					}
				}, delay);
			} else {
				if (reconnectToastIdRef.current !== null) {
					toast.dismiss(reconnectToastIdRef.current);
				}
				// Show a persistent error toast that won't auto-close
				toast.error("Lost connection to chatbot. Please refresh the page.", {
					autoClose: false, // Disable auto-close
					closeOnClick: false, // Prevent closing when clicked
					draggable: false, // Prevent closing by dragging
					closeButton: false, // Show close button for accessibility
					pauseOnHover: false, // Don't pause timer on hover (not relevant since autoClose is false)
				});
				isReconnectingRef.current = false;
				reconnectToastIdRef.current = null;
			}
		};

		ws.current.onerror = () => {
			setIsConnecting(false);
			// Only show error toast if we're not already in reconnection mode
			if (!isReconnectingRef.current) {
				toast.error("Connection error.");
				// Let onclose handle the reconnection
			}
		};

		ws.current.onopen = () => {
			setIsConnecting(false);
			// Dismiss any existing reconnection toast
			if (reconnectToastIdRef.current !== null) {
				toast.dismiss(reconnectToastIdRef.current);
				reconnectToastIdRef.current = null;
			}

			if (reconnectAttemptsRef.current > 0) {
				toast.success("Connection restored!");
				// Reset both the ref and state
				reconnectAttemptsRef.current = 0;
				setReconnectAttempts(0);
			}

			// Reset reconnection state
			isReconnectingRef.current = false;
		};
	};

	const handleClearMessages = () => {
		if (ws.current && ws.current.readyState === WebSocket.OPEN) {
			setIsClearing(true);
			const serverMessage: ServerMessage = {
				type: "action",
				content: "clear",
			};
			ws.current.send(JSON.stringify(serverMessage));
		} else {
			toast.error("WebSocket is not open.");
		}
	};

	const addMessage = (message: LocalMessage) => {
		setMessages((prevMessages) => [...prevMessages, message]);
		setLoadingPhase(null);
	};

	const sanitizeMessage = (message: string) => {
		// Remove any HTML tags and trim whitespace
		return message.replace(/<\/?[^>]+(>|$)/g, "").trim();
	};

	const handleSendMessage = () => {
		if (inputMessage.trim() === "") return;

		const sanitizedMessage = sanitizeMessage(inputMessage);
		const userMessage: LocalMessage = {
			id: generateMessageId(),
			content: sanitizedMessage,
			sender: "user",
			timestamp: new Date().getTime(),
		};

		addMessage(userMessage);
		setInputMessage("");
		setLoadingPhase("thinking");

		if (ws.current && ws.current.readyState === WebSocket.OPEN) {
			const serverMessage: ServerMessage = {
				type: "message",
				content: userMessage.content,
			};

			ws.current.send(JSON.stringify(serverMessage));
		} else {
			console.error(
				"WebSocket is not open. Current state:",
				ws.current?.readyState
			);
			toast.error("Lost connection to server. Attempting to reconnect...");
		}
	};

	const handleMouseDown = (e: React.MouseEvent) => {
		e.preventDefault();
		const startX = e.clientX;
		const startY = e.clientY;
		const startWidth = chatSize.width;
		const startHeight = chatSize.height;

		const handleMouseMove = (e: MouseEvent) => {
			const newWidth = startWidth - (e.clientX - startX);
			const newHeight = startHeight - (e.clientY - startY);
			setChatSize({
				width: Math.max(newWidth, 500), // Minimum width
				height: Math.max(newHeight, 600), // Minimum height
			});
		};

		const handleMouseUp = () => {
			document.removeEventListener("mousemove", handleMouseMove);
			document.removeEventListener("mouseup", handleMouseUp);
		};

		document.addEventListener("mousemove", handleMouseMove);
		document.addEventListener("mouseup", handleMouseUp);
	};

	const scrollToBottom = () => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
		}
	};

	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	const toggleChat = () => {
		if (isOpen) {
			// If chat is open and we're closing it
			closeWebSocket();
			setIsOpen(false);
		} else {
			// If chat is closed and we're opening it
			setIsOpen(true);
		}
	};

	const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setInputMessage(e.target.value);
		if (textareaRef.current) {
			textareaRef.current.style.height = "auto"; // Reset height
			textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set to scroll height
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault(); // Prevent default behavior (newline)
			handleSendMessage();
		}
	};

	const renderedMessages = messages.map((message) => (
		<div
			key={message.id}
			className={`chat-message ${
				message.messageType === "note" ? "chat-note" : `chat-${message.sender}`
			}`}
		>
			<div className="chat-message-header">
				<span className="chat-message-sender">
					{message.sender === "user" ? "You" : "Cacotec"}
				</span>
			</div>
			<div
				className="chat-message-content"
				dangerouslySetInnerHTML={{
					__html: marked(message.content.replace(/【\d+:\d+†source】/g, "")),
				}}
			/>
			<div className="chat-message-footer">
				<span className="chat-message-timestamp">
					{new Date(message.timestamp).toLocaleTimeString([], {
						hour: "2-digit",
						minute: "2-digit",
					})}
				</span>
			</div>
		</div>
	));

	const isInputDisabled = React.useMemo(() => {
		// Disable when:
		// 1. We're waiting for a response (loadingPhase !== null)
		// 2. We're reconnecting (isReconnectingRef.current === true)
		// 3. WebSocket doesn't exist or isn't in OPEN state
		return (
			!isOnline ||
			loadingPhase !== null ||
			isReconnectingRef.current ||
			reconnectAttempts >= maxReconnectAttempts ||
			isConnecting
		);
	}, [isOnline, loadingPhase, reconnectAttempts, isConnecting]);

	return (
		<div className="chatbot-container">
			<button className="chat-icon" onClick={toggleChat}>
				<Icon name="chat-bubble" />
			</button>
			{isOpen && (
				<div
					className="chat-window"
					style={{ width: chatSize.width, height: chatSize.height }}
				>
					<div className="chat-header">
						<h3 className="chat-header-title">Cacotec Help</h3>
						<button
							className="close-button"
							data-dismiss="drawer"
							aria-label="Close"
							onClick={() => {
								closeWebSocket();
								setIsOpen(false);
							}}
						>
							<Icon name="cross-rounded" />
						</button>
					</div>
					<div className="resize-handle" onMouseDown={handleMouseDown} />
					<div className="chat-messages" ref={messagesEndRef}>
						{renderedMessages}
						{loadingPhase && (
							<div className="loading">
								{loadingPhase === "thinking"
									? "Thinking"
									: loadingPhase === "typing"
									? "Typing"
									: loadingPhase === "still-typing"
									? "Still typing"
									: "More typing"}
							</div>
						)}
						<div ref={messagesEndRef} />
					</div>
					<div className="chat-input">
						<textarea
							ref={textareaRef}
							value={inputMessage}
							onChange={handleInputChange}
							onKeyDown={handleKeyDown}
							placeholder="Type your message..."
							maxLength={300}
							rows={1}
							disabled={isInputDisabled}
						/>
						<button
							className="button button--primary"
							onClick={handleSendMessage}
							disabled={isInputDisabled}
						>
							Send
						</button>
					</div>
					<div
						onClick={handleClearMessages}
						className="clear-chat-button"
						style={{
							opacity: isClearing ? 0.5 : 1,
							cursor: isClearing ? "not-allowed" : "pointer",
						}}
					>
						{isClearing ? "Clearing..." : "Clear chat"}
					</div>
				</div>
			)}
		</div>
	);
};

export default ChatBot;
