import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { read, utils, writeFile } from "xlsx";
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom-v5-compat";

import Icon from "../Shared/Icon";
import useDataApi from "Hooks/fetchHook";
import HelpTooltip from "Components/Shared/HelpTooltip";
import { EndpointPrefix, isFactory } from "Models/UserModels";
import GlobalDispatch from "Dispatches/GlobalDispatch";
import Loading from "../Shared/Loading";
import {
	XLSX_STYLE_COLUMNS_NAMES_VALUES,
	XLSX_STYLE_COLUMNS_NAMES,
} from "Models/OrderModels";
import ChooseOption from "../Shared/ChooseOption";
import useWindowSize from "Hooks/windowSizeHook";
import { useFetchBrands } from "Hooks/queryHooks/useFetchBrands";
import { queryClient } from "react-query/queryClient";
import { QUERY_KEYS } from "react-query/constants";
import ImportStylesList from "Components/Styles/ImportStylesList";

interface IProps {
	showImportStylesDrawer: boolean;

	setShowImportStylesDrawer: (showImportStylesDrawer: boolean) => void;
	refreshStyles: (forceRefresh: boolean) => void;
}

const ImportStyles: React.FunctionComponent<IProps> = ({
	showImportStylesDrawer,
	setShowImportStylesDrawer,
	refreshStyles,
}) => {
	const { t } = useTranslation();
	const windowSize = useWindowSize();

	const useImportStyles = useDataApi();
	const useConflictCheck = useDataApi();

	const {
		user: { account_type_id, brand_id },
		setError,
	} = useContext(GlobalDispatch);

	const { brands, isLoadingBrands } = useFetchBrands(account_type_id, setError);

	const PAGE_HEADER_HEIGHT = 120;
	const PAGE_FOOTER_HEIGHT = 130;
	const TABLE_HEADER_HEIGHT = 32;

	const [styles, setStyles] = useState([]) as any;
	const [invalidInfo, setInvalidInfo] = useState(undefined) as any;
	const [isLoadingReadExcel, setIsLoadingReadExcel] = useState(false) as any;
	const [stylesHeight, setStylesHeight] = useState(0);
	const [brandsForFactory, setBrandsForFactory] = useState([]);
	const [currentBrandId, setCurrentBrandId] = useState(0);
	const [importSuccessMessage, setImportSuccessMessage] = useState(
		undefined
	) as any;
	const [invalidStylesArrayIndexes, setInvalidStylesArrayIndexes] = useState(
		[]
	);
	const [isLoading, setIsLoading] = useState(false);

	const readFileRef = useRef() as any;
	const importStylesRef = useRef() as any;
	const missingMandatoryInfoRef = useRef() as any;
	const duplicateValuesErrorRef = useRef() as any;
	const fileName = useRef() as any;
	const fileInfoRef = useRef() as any;

	useEffect(() => {
		const onReloadPage = (e: any) => {
			if (styles.length > 0) {
				e.preventDefault();
				e.returnValue = t(
					"All changes will be lost. Are you sure you want to continue?"
				);
			}
		};

		window.addEventListener("beforeunload", onReloadPage);

		if (importSuccessMessage) {
			window.removeEventListener("beforeunload", onReloadPage);
		}

		return () => {
			window.removeEventListener("beforeunload", onReloadPage);
		};
	}, [styles, importSuccessMessage]);

	useEffect(
		() => {
			if (showImportStylesDrawer) {
				let height = windowSize[1] - PAGE_HEADER_HEIGHT - PAGE_FOOTER_HEIGHT;

				if (fileInfoRef?.current) {
					height -= fileInfoRef.current.clientHeight;
				}

				setStylesHeight(height);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			windowSize,
			showImportStylesDrawer,
			duplicateValuesErrorRef.current,
			missingMandatoryInfoRef?.current,
			fileInfoRef?.current,
			invalidInfo,
			invalidStylesArrayIndexes,
		]
	);

	useEffect(() => {
		if (showImportStylesDrawer) {
			if (isFactory(account_type_id)) {
				setBrandsForFactory(brands);
			} else {
				setCurrentBrandId(brand_id);
			}
		}
	}, [showImportStylesDrawer]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (importStylesRef.current && showImportStylesDrawer) {
			setTimeout(() => importStylesRef.current.focus(), 50);
			document.body.style.overflowY = "hidden";
		} else {
			document.body.style.overflowY = "auto";
		}
	}, [showImportStylesDrawer, importStylesRef]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ******************** Conflict check API call ************************ */
	useEffect(() => {
		const { error, details } = useConflictCheck;
		if (error) {
			toast.error(t("Unable to save styles."));
			if (details) {
				setInvalidInfo(details);
			}
		}
	}, [useConflictCheck.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { message } = useConflictCheck.data;

		if (message) {
			if (
				message.length === 0 ||
				(message.length > 0 &&
					window.confirm(
						`This action will permanently overwrite the following style${
							message.length > 1 ? "s" : ""
						}: ${message.slice(0, 20).map((style: any) => `\n${style}`)}${
							message.length > 20
								? `\n and ${message.length - 20} other styles`
								: ""
						}.\n\n Are you sure you want to proceed with this file?`
					))
			) {
				const filterByBrand = isFactory(account_type_id)
					? `?brandId=${currentBrandId}`
					: "";
				useImportStyles.doFetch(
					`/${EndpointPrefix[account_type_id]}/styles${filterByBrand}`,
					{ styles, fileName: fileName.current },
					"POST"
				);
			}
		}
	}, [useConflictCheck.data]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ******************************************************************* */

	/* ******************** Import styles API call ************************ */
	useEffect(() => {
		const { error, details } = useImportStyles;
		if (error) {
			toast.error(t("Unable to save styles."));
			if (details) {
				setInvalidInfo(details);
			}
		}
	}, [useImportStyles.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { data } = useImportStyles;
		if (data.message) {
			toast.success(t("Styles successfully added!"));
			setImportSuccessMessage(data.message);
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.INITIAL_VALUES, account_type_id, currentBrandId],
			});
		}
	}, [useImportStyles.data]); // eslint-disable-line react-hooks/exhaustive-deps

	/* ****************************************************************** */

	/* ******************** Loading import styles state ************************ */
	useEffect(() => {
		if (useImportStyles.isLoading || useConflictCheck.isLoading) {
			setIsLoading(true);
		} else if (!useImportStyles.isLoading && !useConflictCheck.isLoading) {
			setIsLoading(false);
		}
	}, [useImportStyles.isLoading, useConflictCheck.isLoading]);

	/* ****************************************************************** */

	const addNewStyle = (item: any) => {
		const {
			STYLE_NUMBER,
			STYLE_DESCRIPTION,
			SEASON,
			MADE_IN,
			FABRIC_CONTENT,
			CARE_INSTRUCTIONS,
			ADDITIONAL_COMPONENTS,
			INCLUDE_QR_CODE,
		} = XLSX_STYLE_COLUMNS_NAMES;
		const style = {} as any;

		if (item[STYLE_NUMBER] && `${item[STYLE_NUMBER]}`.trim()) {
			style.style_number = `${item[STYLE_NUMBER]}`
				.trim()
				.replace(/\n/g, " ")
				.replace(/\r/g, " ");
		}

		if (item[STYLE_DESCRIPTION] && `${item[STYLE_DESCRIPTION]}`.trim()) {
			style.style_description = `${item[STYLE_DESCRIPTION]}`
				.trim()
				.replace(/\n/g, " ")
				.replace(/\r/g, " ");
		}

		if (item[SEASON] && `${item[SEASON]}`.trim()) {
			style.season = `${item[SEASON]}`
				.trim()
				.replace(/\n/g, " ")
				.replace(/\r/g, " ");
		}

		if (item[MADE_IN] && `${item[MADE_IN]}`.trim()) {
			style.made_in = `${item[MADE_IN]}`
				.trim()
				.replace(/\s+/g, " ")
				.replace(/\n/g, " ")
				.replace(/\r/g, " ")
				.toUpperCase();
		}

		if (item[FABRIC_CONTENT] && `${item[FABRIC_CONTENT]}`.trim()) {
			style.fabric_content = `${item[FABRIC_CONTENT]}`
				.trim()
				?.replaceAll(";", "\n")
				.replace(/\n\s*/g, "\n")
				.split("\n")
				.map((composition: any) =>
					composition
						.trim()
						.replace(/\s+/g, " ")
						.replaceAll(" :", ":")
						.replaceAll(" ,", ",")
				)
				.join(";")
				.toUpperCase();
		}

		if (item[CARE_INSTRUCTIONS] && `${item[CARE_INSTRUCTIONS]}`.trim()) {
			const careInstructionsWithoutDuplicates = Array.from(
				new Set(
					`${item[CARE_INSTRUCTIONS]}`
						.trim()
						.replace(/\n\s*/g, "\n")
						.split("\n")
				)
			);
			style.care_instructions = careInstructionsWithoutDuplicates.map(
				(careInstruction: any) =>
					careInstruction.trim().replace(/\s+/g, " ").toUpperCase()
			);
		}

		if (
			item[ADDITIONAL_COMPONENTS] &&
			`${item[ADDITIONAL_COMPONENTS]}`.trim()
		) {
			const additionalComponentsWithoutDuplicates = Array.from(
				new Set(
					`${item[ADDITIONAL_COMPONENTS]}`
						.trim()
						.replace(/\n\s*\n/g, "\n")
						.split("\n")
				)
			);
			style.additional_components = additionalComponentsWithoutDuplicates.map(
				(additionalComponent: any) =>
					additionalComponent.trim().replace(/\s+/g, " ").toUpperCase()
			);
		}

		if (`${item[INCLUDE_QR_CODE]}`?.trim().toUpperCase() === "YES") {
			style.include_qr_code = true;
		}
		return style;
	};

	const update_sheet_range = (ws: any) => {
		const range = { s: { r: Infinity, c: Infinity }, e: { r: 0, c: 0 } };
		Object.keys(ws)
			.filter(function (x) {
				return x.charAt(0) !== "!";
			})
			.map(utils.decode_cell)
			.forEach(function (x) {
				range.s.c = Math.min(range.s.c, x.c);
				range.s.r = Math.min(range.s.r, x.r);
				range.e.c = Math.max(range.e.c, x.c);
				range.e.r = Math.max(range.e.r, x.r);
			});
		ws["!ref"] = utils.encode_range(range);
		return ws;
	};

	const onReadExcel = (e: any) => {
		const allowedTypes = new Set([
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			"application/vnd.ms-excel",
		]);
		if (!allowedTypes.has(e.target?.files[0]?.type)) {
			alert(t("You must choose an excel file (.xls or .xlsx)."));
		} else {
			fileName.current = e.target.files[0].name;
			const reader = new FileReader() as any;
			setIsLoadingReadExcel(true);
			if (e.target.files[0]) {
				reader.readAsArrayBuffer(e.target.files[0]);
			}
			reader.onload = (e: any) => {
				const bufferArray = e.target.result;
				const workbook = read(bufferArray, { type: "buffer" });
				// select worksheet from file by index
				const worksheet = update_sheet_range(
					workbook.Sheets[workbook.SheetNames[0]] as any
				);

				const headers = [];
				const columnCount = utils.decode_range(worksheet["!ref"]).e.c + 1;
				for (let i = 0; i < columnCount; ++i) {
					headers[i] = worksheet[`${utils.encode_col(i)}1`]?.v;
				}
				const headersAreValid = headers.every(
					(columnName?: XLSX_STYLE_COLUMNS_NAMES_VALUES) =>
						columnName &&
						Object.values(XLSX_STYLE_COLUMNS_NAMES).includes(columnName)
				);

				if (!headersAreValid) {
					alert(
						t(
							"The format in your file is invalid, please use the Cacotec format and try again."
						)
					);
				} else {
					const data = utils.sheet_to_json(worksheet) as any;
					if (data.length === 0) { 
						toast.error(t("There are no styles in the file included."));
					} else {
						const stylesList = [] as any;

						data.forEach((item: any, index: number) => {
							stylesList.push({ ...addNewStyle(item), id: index });
						});

						setStyles(stylesList);
						missingMandatoryInfoRef.current = stylesList.find(
							(style: any) =>
								!style.style_number ||
								!style.made_in ||
								!style.fabric_content ||
								!style.care_instructions ||
								style.care_instructions.length === 0
						);

						const styleNames = stylesList.map((style: any) => style.style_number);
						duplicateValuesErrorRef.current = styleNames.find(
							(style: any, index: any) => styleNames.indexOf(style) !== index
						);
					}
				}
				// Reset the input value to allow re-uploading the same file
				if (readFileRef.current) {
					readFileRef.current.value = ""
				}
				setIsLoadingReadExcel(false)
			};
		}
	};

	const updateStyle = (styleNumber: string, updatedFields: any) => {
		const updatedStyles = styles.map((style: any) =>
			style.style_number === styleNumber
				? { ...style, ...updatedFields }
				: style
		);
		setStyles(updatedStyles);
	};

	const onCheckDuplicatesAndSaveStyles = () => {
		const styleNumbers = styles.map((style: any) => style.style_number);
		const filterByBrand = isFactory(account_type_id)
			? `?brandId=${currentBrandId}`
			: "";
		useConflictCheck.doFetch(
			`/${EndpointPrefix[account_type_id]}/styles/conflictCheck${filterByBrand}`,
			{ style_numbers: styleNumbers },
			"POST"
		);
	};

	const onCloseDrawer = (onButtonClicked?: boolean) => {
		if (
			(styles.length > 0 &&
				onButtonClicked &&
				window.confirm(
					t("All changes will be lost. Are you sure you want to continue?")
				)) ||
			!onButtonClicked ||
			styles.length === 0
		) {
			setShowImportStylesDrawer(false);
			onClear();
			if (readFileRef.current) {
				readFileRef.current.value = "";
			}
			setCurrentBrandId(0);
			setImportSuccessMessage(undefined);
		}
	};

	const onBackToStyles = () => {
		refreshStyles(true);
		onCloseDrawer();
	};

	const onKeyDown = (event: any) => {
		if (event.key === "Escape") {
			onCloseDrawer(true);
		}
	};

	const onDrop = (event: any) => {
		event.stopPropagation();
		event.preventDefault();
		onReadExcel({ target: event.dataTransfer });
	};

	const onClear = () => {
		setStyles([]);
		setInvalidInfo(undefined);
		setInvalidStylesArrayIndexes([]);
	};

	const brandChosen = (brand: any) => {
		const { id: brandId } = brand;
		setCurrentBrandId(brandId);
	};

	const exportFile = () => {
		const ws = utils.aoa_to_sheet([Object.values(XLSX_STYLE_COLUMNS_NAMES)]);
		const wscols = [
			{ wch: 12 },
			{ wch: 20 },
			{ wch: 12 },
			{ wch: 25 },
			{ wch: 45 },
			{ wch: 45 },
			{ wch: 30 },
			{ wch: 7 },
		];

		ws["!cols"] = wscols;
		const wb = utils.book_new();
		utils.book_append_sheet(wb, ws, "SheetJS");
		/* generate  file and send to client */
		writeFile(wb, "ImportStylesExcelFormat.xlsx");
	};

	return (
		<aside
			className={`drawer ${showImportStylesDrawer && `show`}`}
			id="import-styles"
			role="menu"
			ref={importStylesRef}
			aria-label={t("Import Styles")}
			style={{ width: "100vw", backgroundColor: "#f4f4f4" }}
			onKeyDown={onKeyDown}
		>
			<button
				className="drawer__close btn-no-style"
				data-dismiss="drawer"
				aria-label={t("Close")}
				onClick={() =>
					importSuccessMessage ? onBackToStyles() : onCloseDrawer(true)
				}
			>
				<Icon name="cross-rounded" />
			</button>
			<Loading
				show={isLoadingBrands}
				text={t("Loading...")}
				imgClass="block-center"
				divClass="col-sm-12"
			/>

			{!importSuccessMessage && (
				<>
					{!isLoadingBrands &&
						brandsForFactory &&
						brandsForFactory.length > 0 &&
						!currentBrandId && (
							<ChooseOption
								options={brandsForFactory}
								onChooseOption={brandChosen}
								type={t("Brand")}
								action={t("Import Styles")}
							/>
						)}

					{!isLoadingBrands && currentBrandId && (
						<div className="drawer__content">
							<header
								className="drawer__header"
								style={{ paddingBottom: "20px" }}
							>
								<div className="flex-center-secondary-axis mt--sm">
									<h3 className="drawer__title">{t("Import Styles")}</h3>
									<HelpTooltip helpFor="ImportStyles" />
								</div>
							</header>
							<section className="drawer__main">
								<Loading
									show={isLoadingReadExcel}
									text={t("Loading...")}
									imgClass="block-center"
									divClass=""
								/>

								{styles.length === 0 && (
									<div className="form-group">
										<div
											className="form-control-file__label"
											onDrop={onDrop}
											onDragOver={(event) => event.preventDefault()}
										>
											<div>{t("Drop file here")}</div>
											<div className="mb--sm">{t("or")}</div>
											<label className="form-control-styles-file__label">
												{t("Select File")}
												<input
													className="form-control-styles-file"
													ref={readFileRef}
													type="file"
													onChange={onReadExcel}
													accept=".xls, .xlsx"
												/>
											</label>
										</div>
										<div
											className="mt--sm text--quiet text--micro"
											style={{ textAlign: "center" }}
										>
											<p>
												{t(
													"File needs to be in a format defined by Cacotec. To"
												)}{" "}
												{t("download a file in a correct format")}{" "}
												{t("that you can start using straightaway, click")}{" "}
												<button
													className="btn-no-style btn-link"
													onClick={exportFile}
												>
													{t("here")}
												</button>
												.{" "}
												{t(
													"You can also download a file with detailed explanations and examples"
												)}{" "}
												<Link
													to="/files/ImportStylesExcelExample.xlsx"
													download
													target="_blank"
												>
													{t("here")}
												</Link>
												.
											</p>
										</div>
									</div>
								)}

								{styles.length > 0 && (
									<>
										<div style={{ paddingBottom: "20px" }} ref={fileInfoRef}>
											{invalidInfo && invalidStylesArrayIndexes.length > 0 && (
												<h3 className="link--danger mb--sm">
													{t(
														"Styles could not be added, please resolve issues and try again."
													)}
												</h3>
											)}

											{duplicateValuesErrorRef.current && (
												<h3 className="link--danger mb--sm">
													{t("Duplicate style number found")}:{" "}
													{duplicateValuesErrorRef.current}
												</h3>
											)}

											{missingMandatoryInfoRef.current && (
												<h3 className="link--danger mb--sm">
													{t(
														"Mandatory fields missing, please add all information and try again."
													)}
												</h3>
											)}

											<div>
												<b>{t("Selected file")}:</b> {fileName.current}
											</div>

											{invalidStylesArrayIndexes.length > 0 && (
												<>
													<div className="mt--sm">
														{" "}
														Styles ready to be added:{" "}
														<b>
															{styles.length - invalidStylesArrayIndexes.length}
														</b>
													</div>
													<div>
														{" "}
														Styles with errors (shown below):{" "}
														<b>{invalidStylesArrayIndexes.length}</b>
													</div>
												</>
											)}
										</div>

										{invalidInfo &&
											Object.keys(invalidInfo).length > 0 &&
											invalidStylesArrayIndexes.length === 0 && (
												<div className="mt--sm">
													{" "}
													Styles ready to be added:{" "}
													<b>
														{styles.length - invalidStylesArrayIndexes.length}
													</b>
												</div>
											)}

										<ImportStylesList
											styles={styles}
											stylesListHeight={stylesHeight}
											stylesListHeaderHeight={TABLE_HEADER_HEIGHT}
											invalidInfo={invalidInfo}
											invalidStylesArrayIndexes={invalidStylesArrayIndexes}
											isLoading={isLoading}
											setInvalidStylesArrayIndexes={
												setInvalidStylesArrayIndexes
											}
											updateStyle={updateStyle}
											onFinalize={onCheckDuplicatesAndSaveStyles}
										/>
									</>
								)}
							</section>

							{styles.length > 0 && (
								<div className="mt--sm">
									{t("Total styles")}: <b>{styles.length}</b>
								</div>
							)}

							<footer className="drawer__footer">
								{styles.length > 0 && (
									<div style={{ marginRight: "30px" }}>
										<button
											className="button btn-link"
											style={{ padding: "0px 0px" }}
											onClick={onClear}
										>
											{t("Clear")}
										</button>
									</div>
								)}

								<div className="txtr">
									<div
										style={{ width: "186px" }}
										className="flex-center-primary-axis"
									>
										<Loading
											show={isLoading}
											text={t("Loading...")}
											imgClass="imgLoading"
											divClass=""
										/>
									</div>

									{!isLoading && styles.length > 0 && (
										<div
											data-tooltip-content={
												missingMandatoryInfoRef.current
													? t(
															"Please populate all mandatory fields in order to continue. Missing fields are marked in red."
													  )
													: t(
															"Please remove or change duplicate style number in order to continue."
													  )
											}
											data-tooltip-id="finalizeImport"
											data-multiline={true}
										>
											<button
												className={`button ${
													missingMandatoryInfoRef.current ||
													duplicateValuesErrorRef.current
														? "button--disabled"
														: "button--primary"
												}`}
												onClick={onCheckDuplicatesAndSaveStyles}
											>
												{t("Finalize import")}
											</button>
											{(missingMandatoryInfoRef.current ||
												duplicateValuesErrorRef.current) && (
												<Tooltip
													id="finalizeImport"
													className="reactTooltip box--shadowed"
													float
												/>
											)}
										</div>
									)}
								</div>
							</footer>
						</div>
					)}
				</>
			)}
			{importSuccessMessage && (
				<div className="main__content">
					<div className="container center">
						<svg
							className="checkmark"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 52 52"
						>
							<circle
								className="checkmark__circle"
								cx="26"
								cy="26"
								r="25"
								fill="none"
							/>
							<path
								className="checkmark__check"
								fill="none"
								d="M14.1 27.2l7.1 7.2 16.7-16.8"
							/>
						</svg>
						<h1 className="txtc">{t("Style import successful!")}</h1>
						<div className="txtc" style={{ fontSize: "18px" }}>
							<div>
								{importSuccessMessage.insertCnt} {t("new style")}
								{importSuccessMessage.insertCnt !== 1 && t("s")} {t("added")}
							</div>
							<div>
								{importSuccessMessage.updateCnt} {t("existing style")}
								{importSuccessMessage.updateCnt !== 1 && t("s")} {t("updated")}
							</div>
						</div>
						<br />

						<button
							className="normal txtc center btn-no-style btn-link"
							onClick={onBackToStyles}
						>
							{t("Back to styles")}
						</button>
					</div>
				</div>
			)}
		</aside>
	);
};

export default ImportStyles;
